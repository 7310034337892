import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Main from './store/main';
//import { vuexLocal } from "@/plugins/vuex-persist";

const store = new Vuex.Store({
    modules: {
        Main
    },
    //plugins: [vuexLocal.plugin]
});

export { store };