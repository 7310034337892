<template>
  <div :class="{ 'page': true, 'prevent-scroll': popupHelpComponent != null }" id="app">
    <Header />

    <router-view />

    <PopupHelp />
  </div>
</template>

<script>
import Header from "@/components/Base/Header";
import PopupHelp from "@/components/Base/PopupHelp";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'App',
  components: {PopupHelp, Header},
  computed: {
    ...mapGetters(['popupHelpComponent', 'options'])
  },
  methods: {
    ...mapActions(['updateMobileStatus', 'calculateResults', 'updateOption']),
    getWidth() {
      return Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth);
    },
    resizeHandler() {
      this.updateMobileStatus(this.getWidth() < 1100);
    }
  },
  mounted() {
    this.calculateResults();
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();

    // init option on load
    this.updateOption({
      option: 'fireResistance',
      value: this.options.fireResistance
    });

    this.updateOption({
      option: 'cantilever',
      value: this.options.cantilever
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  }
};
</script>

<style lang="scss">
.page {
  width: 100%;
  height: 100%;
}

main {
  max-width: $content-width;
  width: calc(100% - 16px * 2);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 33px;

  .page-header + & {
    padding-top: 113px;
  }
}

.prevent-scroll {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
