<template>
  <header :class="{ 'page-header': true, 'desktop': !isMobile, 'mobile': isMobile, 'navbar-open': navbarOpen }">
    <div class="page-header-inner">
      <div v-if="isMobile" class="page-header-hamburger">
        <div @click="navbarOpen = !navbarOpen" :class="{ 'hamburger hamburger--3dx': true, 'is-active': navbarOpen }">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
          <div class="hamburger-text">Menü</div>
        </div>
      </div>

      <div class="page-header-logo">
        <router-link :to="'/'">
          <img :src="require('@/assets/logo.png')" />
        </router-link>
      </div>

      <div class="page-header-nav">
        <div v-if="isMobile" class="page-header-hamburger">
          <div @click="navbarOpen = !navbarOpen" :class="{ 'hamburger hamburger--3dx': true, 'is-active': navbarOpen }">
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
            <div class="hamburger-text">Schließen</div>
          </div>
        </div>

        <nav>
          <ul>
            <li @click="navbarOpen = !navbarOpen" v-for="navItem in normalNavItems" :key="navItem.id" :class="getClasses(navItem.id, navItem.name)">
              <router-link :to="navItem.path">{{ navItem.name }}</router-link>
            </li>
            <li @click="navbarOpen = !navbarOpen" v-for="navItem in mobileExtraNavItems" :key="navItem.id">
              <router-link :to="navItem.path">{{ navItem.name }} <img v-if="navItem.icon" :src="navItem.icon"></router-link>
            </li>
          </ul>
        </nav>
      </div>

      <div class="page-header-other">
        <router-link v-for="navItem in extraNavItems" :key="navItem.id" :to="navItem.path">{{ navItem.name }} <img v-if="navItem.icon" :src="navItem.icon"></router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { routes } from "@/config";
export default {
  name: "Header",
  data() {
    return {
      routes: routes,
      navbarOpen: false
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    normalNavItems() {
      return this.routes.filter(x => !x.external);
    },
    extraNavItems() {
      return this.routes.filter(x => x.external);
    },
    mobileExtraNavItems() {
      if(!this.isMobile)
        return [];

      return this.routes.filter(x => x.external);
    }
  },
  methods: {
    getClasses(id, name) {
      let result = '';

      if(name === this.$route.name)
        result += " active";

      return result;
    }
  }
};
</script>

<style lang="scss">
.page-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  max-width: 100%;
  height: $height-header;

  background-color: #FFFFFF;
  box-shadow: 0px 1px 0px #CAD3D9;

  &.mobile {
    height: $height-header-mobile;

    .page-header-inner {
      justify-content: initial;
    }

    .page-header-logo {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      a {
        display: flex;
        width: 100%;
        height: 100%;

        justify-content: center;
      }
    }

    .page-header-nav {
      transform: translateX(0);

      .page-header-hamburger {
        height: $height-header-mobile;

        box-shadow: 0px 1px 0px #CAD3D9;
      }

      nav {
        height: auto;
        padding: 16px 16px 0 16px;

        ul {
          flex-direction: column;
          gap: 0;

          li {
            height: auto;

            border-bottom: 1px solid #CAD3D9;

            a {
              justify-content: initial;
              padding: 16px 0 16px 0;
              font-size: 1.313rem;
            }

            &.active {
              &:after {
                content: initial;
              }
            }
          }
        }
      }
    }

    .page-header-hamburger {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      .hamburger {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .hamburger-text {
        margin-top: 10px;

        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }

    &.navbar-open {
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(41,49,54,0.6);
      }

      .page-header-nav {
        transform: translateX(0);

        .page-header-hamburger {
          height: $height-header-mobile;

          box-shadow: 0px 1px 0px #CAD3D9;
        }

        nav {
          height: auto;
          padding: 16px 16px 0 16px;

          ul {
            flex-direction: column;
            gap: 0;

            li {
              height: auto;

              border-bottom: 1px solid #CAD3D9;

              a {
                justify-content: initial;
                padding: 16px 0 16px 0;
                font-size: 1.313rem;
              }

              &.active {
                &:after {
                  content: initial;
                }
              }
            }
          }
        }
      }
    }

    .page-header-nav {
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      width: 85%;

      transition: 0.3s transform;
      overflow-y: auto;
      background: #FFFFFF;
      color: #293136;
      transform: translateX(-100%);

      &.is-open {
        transform: translateX(0);
      }
    }

    .page-header-other {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      visibility: hidden;
    }
  }

  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    max-width: $content-width;
    width: calc(100% - var(--layout-content-padding, 20px) * 2) !important;
    margin: auto;

    font-family: futura-pt-condensed, sans-serif;
    font-weight: bold;

    .page-header-logo {
      img {
        padding: 8px 0 8px 0;
        max-height: 100%;
      }
    }

    .page-header-nav {
      nav {
        height: 100%;

        ul {
          display: flex;
          flex-direction: row;
          gap: 32px;
          height: 100%;

          li {
            height: 100%;

            &:hover {
              color: #E3000B;
            }

            &.active {
              position: relative;

              color: #E3000B;

              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;

                background-color: #E3000B;
              }
            }

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              img {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    .page-header-other {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>