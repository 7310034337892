<template>
  <div v-show="popupHelpComponent" class="popup-wrapper">
    <div class="popup-container animate__animated animate__fadeIn">
      <component class="help-container" :is="popupHelpComponent"></component>

      <div class="close-container">
        <button @click="updatePopupHelpComponent(null)">SCHLIESSEN</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "PopupHelp",
  computed: {
    ...mapGetters(['popupHelpComponent'])
  },
  methods: {
    ...mapActions(['updatePopupHelpComponent'])
  }
}
</script>

<style lang="scss">
.popup-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 49, 54, 0.6);
  z-index: 41;

  .popup-container {
    width: calc(100% - 32px);
    max-width: 800px;
    max-height: calc(100% - 64px);
    position: relative;
    top: 50%;
    margin: 0 auto;
    padding: 48px 32px 0 32px;
    transform: translateY(-50%);
    background: #FFFFFF;
    overflow-y: auto;

    .help-container {
      font-family: futura-pt-book, sans-serif;
      font-size: 0.762rem;

      p {
        margin-bottom: 48px;
        line-height: 1.7rem;
      }

      h2 {
        position: relative;
        margin-bottom: 32px;
        padding-bottom: 8px;

        font-family: futura-pt-condensed, sans-serif;
        font-size: 1rem;
        text-transform: uppercase;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 32px;
          height: 4px;

          background-color: #E3000B;
        }
      }
    }

    .close-container {
      position: relative;
      padding: 32px 0 32px 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -32px;
        width: calc(100% + 64px);
        height: 1px;

        background-color: rgba(202, 211, 217, 1);
      }

      button {
        display: block;
        width: 100%;
        padding: 13px 16px;

        font-family: futura-pt-condensed, sans-serif;
        font-size: 0.762rem;
        font-weight: bold;
        background: transparent;
        border: 1px solid rgba(227, 0, 11, 1);
        color: rgba(227, 0, 11, 1);
        cursor: pointer;
      }
    }
  }
}
</style>