import Vue from 'vue';
import App from './App.vue';

import { router } from "@/plugins/vue-router";
import { store } from "@/plugins/vuex";

import "@/scss/_reset.scss";
import "@/scss/_text.scss";
import "@/scss/_grid.scss";
import "@/scss/_flex.scss";
import "@/scss/_hamburger.scss";

import 'animate.css';

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');
