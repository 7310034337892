import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import { calculatePreDimension, predimensionOptionToFunctionMapping, getPreDimensionResult, setPreDimensionSelection, drucke } from '@/bemessung_predimension';
// eslint-disable-next-line no-unused-vars
import { calculatePaneDetection, panedetectionOptionToFunctionMapping, getPaneDetectionResult, druckePaneDetection } from '@/bemessung_panedetection';

const state = {
    options: {
        // PreDimension Page
        fireResistance: 'f90',
        cantilever: false,

        spanValue: 7,
        cantileverValue: 0,

        plateUnderside: 'inside',
        plateUpperside: 'inside',
        fieldOfApplication: 'apartment',

        payload: 2.7,
        additionalLoad: 1.7,
        singleLoads: [],

        breakthroughLeft: false,
        widthLeft: 0,
        breakthroughMiddle: false,
        widthMiddle: 0,
        breakthroughRight: false,
        widthRight: 0,

        // DiscDetection Page
        system: 'singlePane',
        systemDirection: 'crosswise',
        plateType: 'lepd_32',

        paneWidth: 45,
        paneHeight: 11,
        spacingPost: 1.2,
        paneLoad: 6.3,
    },

    // TODO: REWORK THAT ALL OPTION ITEMS ARE HERE
    optionItems: {
        systemItems: [
            { label: 'Einfeldscheibe', option: 'system', value: 'singlePane' },
            { label: 'Kragscheibe', option: 'system', value: 'cantileverPane' },
            { label: 'Endfeld', option: 'system', value: 'endfield' },
            { label: 'Innenfeld', option: 'system', value: 'infield' }
        ],
        systemDirectionItems: [
            { label: 'Quer zur Scheibenspannrichtung', option: 'systemDirection', value: 'crosswise' },
            { label: 'Längs zur Scheibenspannrichtung', option: 'systemDirection', value: 'lengthwise' },
        ],
        plateTypeItems: [
            { label: 'VMM VSD 16', option: 'plateType', value: 'vsd_16', extraValue: 16 },
            { label: 'VMM VSD 18', option: 'plateType', value: 'vsd_18', extraValue: 18 },
            { label: 'VMM VSD 20', option: 'plateType', value: 'vsd_20', extraValue: 20 },
            { label: 'VMM VSD 22', option: 'plateType', value: 'vsd_22', extraValue: 22 },
            { label: 'VMM VSD 25', option: 'plateType', value: 'vsd_25', extraValue: 25 },
            { label: 'VMM VSD 27', option: 'plateType', value: 'vsd_27', extraValue: 27 },
            { label: 'VMM-L EPD 27', option: 'plateType', value: 'lepd_27', extraValue: 27 },
            { label: 'VMM-L EPD 32', option: 'plateType', value: 'lepd_32', extraValue: 32 },
            { label: 'VMM-L EPD 35', option: 'plateType', value: 'lepd_35', extraValue: 35 },
            { label: 'VMM-L EPD 40', option: 'plateType', value: 'lepd_40', extraValue: 40 }
        ],
    },

    results: {
        preDimension: [
            { ceilingThickness: 12, results: { vsd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 14, results: { vsd: {
                        id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 16, results: { vsd: {
                        id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 18, results: { vsd: {
                        id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } }, borderBottom: true },
            { ceilingThickness: 20, results: { vsd: {
                        id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            }, lscd: {
                        id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 22, results: { vsd: {
                        id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 25, results: { vsd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } }, borderBottom: true },
            { ceilingThickness: 27, results: { vsd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            }, lepd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } }, borderBottom: true },
            { ceilingThickness: 32, results: { lepd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 35, results: { lepd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } } },
            { ceilingThickness: 40, results: { lepd: {
                id: -1,
                present: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                allowed: { load: { value: '0', realValue: 0 }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } },
                utilization: { load: { value: 'OK!', realValue: true }, shearForce: { value: '0', realValue: 0 }, moment: { value: '0', realValue: 0 } }
            } }, borderBottom: true },
        ],

        paneDetection: [
            { leverArmPane: 0, ringAnchorForce: [0, 0], ringAnchorReinforcement: 0, strut: 0, proofStrut: 0, jointConcrete: 0 },
            { maxPostForce: [0, 0], tensionPostReinforcement: 0 },
            { jointForce: [0, 0], jointReinforcement: 0 },
            { connectionForce: [0, 0], ringAnchorReinforcement: 0 },
            { maxLateralForce: 0, maxThrustWithoutDowelling: 0, dowelReinforcement: 0 },
            { maxPaneVelocity: 0, bearingForce: 0 }
        ]
    },

    done: {
        preDimension: false,
        paneDetection: false
    },

    selectedPreDimensionResult: null,

    popupHelpComponent: null,
    isMobile: false
};

const getters = {
    options: (state) => state.options,
    optionItems: (state) => state.optionItems,
    results: (state) => state.results,
    done: (state) => state.done,
    selectedPreDimensionResult: (state) => state.selectedPreDimensionResult,
    popupHelpComponent: (state) => state.popupHelpComponent,
    isMobile: (state) => state.isMobile
};

let mapping = {
    'vsd': [
        0, 1, 3, 4, 5, 6, 8, 9
    ],
    'lepd': [
        16, 17, 18, 19
    ],
    'lscd': [
        22
    ]
};

const actions = {
    printPredimensionResults() {
      drucke();
    },
    pdfPredimensionResults() {
        drucke();
    },
    printPaneDetectionResults({ getters }) {
        druckePaneDetection({
            inputs: {
                system: getters.optionItems.systemItems.find(x => x.value === getters.options.system),
                systemDirection: getters.optionItems.systemDirectionItems.find(x => x.value === getters.options.systemDirection),
                plateType: getters.optionItems.plateTypeItems.find(x => x.value === getters.options.plateType),

                paneWidth: getters.options.paneWidth,
                paneHeight: getters.options.paneHeight,
                spacingPost: getters.options.spacingPost,
                paneLoad: getters.options.paneLoad
            },
            results: getters.results.paneDetection
        });
    },
    pdfPaneDetectionResults({ getters }) {
        druckePaneDetection({
            inputs: {
                system: getters.optionItems.systemItems.find(x => x.value === getters.options.system),
                systemDirection: getters.optionItems.systemDirectionItems.find(x => x.value === getters.options.systemDirection),
                plateType: getters.optionItems.plateTypeItems.find(x => x.value === getters.options.plateType),

                paneWidth: getters.options.paneWidth,
                paneHeight: getters.options.paneHeight,
                spacingPost: getters.options.spacingPost,
                paneLoad: getters.options.paneLoad
            },
            results: getters.results.paneDetection
        });
    },
    calculateResults({ commit, getters }) {
        calculatePreDimension();

        let count = {
            'vsd': 0,
            'lepd': 0,
            'lscd': 0
        };

        for (let i = 0; i < getters.results.preDimension.length; i++) {
            const element = getters.results.preDimension[i];

            Object.keys(element.results).forEach(key => {
                setPreDimensionSelection(mapping[key][count[key]]);

                commit('updateResult', {
                    result: 'preDimension',
                    index: i,
                    key: key,
                    value: getPreDimensionResult()
                });

                commit('updateResultID', {
                    result: 'preDimension',
                    index: i,
                    key: key,
                    value: count[key]++
                });
            });
        }

        calculatePaneDetection();

        commit('updateResult', {
            result: 'paneDetection',
            value: getPaneDetectionResult()
        });

        if (getters.selectedPreDimensionResult !== null)
            setPreDimensionSelection(mapping[getters.selectedPreDimensionResult.key][getters.selectedPreDimensionResult.value]);
    },
    updateMobileStatus({ commit }, value) {
        commit('updateMobileStatus', value);
    },
    updatePopupHelpComponent({ commit }, component) {
        commit('updatePopupHelpComponent', component);
    },
    updateOption({ commit, dispatch }, data) {
        commit('updateOption', data);

        if (predimensionOptionToFunctionMapping[data.option])
            predimensionOptionToFunctionMapping[data.option](data.value, data);

        if (panedetectionOptionToFunctionMapping[data.option])
            panedetectionOptionToFunctionMapping[data.option](data.value, data);

        dispatch('calculateResults');
    },
    updateDone({ commit }, data) {
      commit('updateDone', data);
    },
    updatePreDimensionSelectedResult({ commit }, data) {
        setPreDimensionSelection(mapping[data.key][data.value]);
        commit('updateSelectedPreDimensionResult', data);
    }
};

const mutations = {
    updateMobileStatus: (state, value) => {
        state.isMobile = value;
    },
    updatePopupHelpComponent: (state, component) => {
        state.popupHelpComponent = component;
    },
    updateOption: (state, data) => {
        if(data.insert)
            state.options[data.option].push(data.value);
        else if(data.delete)
            state.options[data.option].splice(data.index, 1);
        else if(data.update)
            Vue.set(state.options[data.option][data.index], data.key, data.value);
        else
            state.options[data.option] = data.value;
    },
    updateDone: (state, data) => {
      state.done[data.result] = data.value;
    },
    updateResult: (state, data) => {
        if (data.result === 'preDimension')
            Vue.set(state.results[data.result][data.index].results, data.key, data.value);
        else if (data.result === 'paneDetection') {
            Vue.set(state.results, data.result, data.value);
        }
    },
    updateResultID: (state, data) => {
        state.results[data.result][data.index].results[data.key].id = data.value;
    },
    updateSelectedPreDimensionResult: (state, data) => {
        state.selectedPreDimensionResult = data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};